import BounceAnimation from "common/components/BounceAnimation";
import TextReveal from "common/components/TextReveal";
import VerticalMarquee from "common/components/VerticalMarquee";
import ArrowRight from "common/icons/arrow_right_blue.svg";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { VMAnimationLottie } from "routes/Products/ValueMedi";
import { VSAnimation } from "routes/Products/Valuesale";

import styled from "styled-components";

const MainContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 3.75rem;
  margin-bottom: 9rem;
  padding: 0 15rem;
  @media screen and (max-width: 800px) {
    margin-bottom: 7.5rem;
    padding: 0;
    width: 80%;
  }
`;

const Title = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-family: Outfit;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media screen and (max-width: 800px) {
    font-size: 1.75rem;
  }
`;

const ContentContainer = styled(motion.div)`
  display: flex;
  padding: 1.875rem;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 1.25rem;
  border: 1px solid #d8d8d8;
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
  @media screen and (max-width: 800px) {
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
  }
`;

const LeftContainer = styled(motion.div)`
  width: 35.1875rem;
  height: 22.8125rem;
  @media screen and (max-width: 800px) {
    width: 20rem;
    height: 15rem;
  }
`;

const RightContainer = styled(motion.div)`
  display: flex;
  width: 35.625rem;
  height: 22.8125rem;
  padding: 0rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  @media screen and (max-width: 800px) {
    height: 12.5rem;
  }
`;

const ContentTitle = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-family: Outfit;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.015rem;
`;

const ContentDescription = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const ButtonContainer = styled(motion.div)`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  color: #006eb2;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
`;

const VSContainer = styled.div`
  transform: scale(0.85);
  margin-top: -3rem;
  @media screen and (max-width: 800px) {
    transform: scale(0.55) translateY(-80px) translateX(-30px);
  }
`;

const VSAnimationContainer = <VSContainer>{VSAnimation}</VSContainer>;

const isMobileView = window.innerWidth < 800;

const DATA = [
  {
    title: isMobileView
      ? "ValueMedi"
      : "Transformation with Clarity and Connection",
    description: isMobileView
      ? "Transformation with Clarity and Connection"
      : "Empowering the supply chain through discoverability and transparency between healthcare distributors, hospitals, and pharmacies.",
    image: VMAnimationLottie,
    to: "/products/valuemedi"
  },
  {
    title: isMobileView ? "ValueSale" : "Unleashing Sales Excellence",
    description: isMobileView
      ? "Unleashing Sales Excellence"
      : "Boosting productivity of the sales teams by automating regular business processes, providing customer behaviour insights and interactive digital product detailing.",
    image: VSAnimationContainer,
    to: "/products/valuesale"
  }
];

const FeaturesHighlight = () => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <MainContainer ref={ref}>
      <Title
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, x: 180 },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.4,
              ease: "easeInOut"
            }
          }
        }}
      >
        What We Offer
      </Title>
      {DATA.map((item) => (
        <ContentContainer
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                duration: 0.4,
                ease: "easeInOut"
              }
            }
          }}
        >
          <LeftContainer
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, x: 800 },
              visible: {
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.4,
                  ease: "easeInOut"
                }
              }
            }}
          >
            {item.image}
          </LeftContainer>
          <RightContainer>
            <ContentTitle>
              <TextReveal text={item.title} />
            </ContentTitle>
            <ContentDescription>
              <TextReveal
                text={item.description}
                animationOptions={{ staggerChildren: 0.01 }}
              />
            </ContentDescription>
            {!isMobileView && (
              <BounceAnimation>
                <ButtonContainer onClick={() => navigate(item.to)}>
                  <span>Learn More</span>
                  <img src={ArrowRight} alt="arrowRight" />
                </ButtonContainer>
              </BounceAnimation>
            )}
          </RightContainer>
        </ContentContainer>
      ))}
    </MainContainer>
  );
};

export default FeaturesHighlight;
